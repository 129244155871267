import {css, html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../../WebComponent.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import AuthorizationFilter from '../../Authorization/AuthorizationFilter.js'
import DefaultMobileItem from '../../../components/DefaultMobileItem/DefaultMobileItem.js'
import VerantwortlichePersonColumns from './VerantwortlichePersonColumns.js'
import VerantwortlichePersonService from '../../../services/VerantwortlichePersonService.js';
import VerantwortlichePersonPrioCell from './VerantwortlichePersonPrioCell.js';
import '@isceco/widget-library2/basic-elements/List/StaticList.js'
import {geschaeftsVorfallNavigationLinks, geschaeftsVorfallNavigationLinksHelper} from '../Geschaeftsvorfall.js'
import '../../../components/Title/Title.js'
import GeschaeftsvorfallNavigation from '../Navigation/GeschaeftsvorfallNavigation'


export default class VerantwortlichePerson extends WebComponent {

  constructor(isClosedForKanton, isClosedForSeco) {
    super()
    this.isClosedForKanton = isClosedForKanton
    this.isClosedForSeco = isClosedForSeco
    this.verantwortlichePersonService = new VerantwortlichePersonService()
  }

  get css() {
    return css`
      .buttons {
        margin-top: 3em;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 0.5em;
      }

      .title-content {
        display: flex;
        gap: 1em;
        margin-bottom: 0.5em;
        align-items: center;
      }

      .title-content .status {
        font-size: 1.5em;
        margin-top: 0.5em;
      }
    `
  }

  get translationFile() {
    return './views/Geschaeftsvorfall/VerantwortlichePerson/i18n.json'
  }

  async connectedCallback() {
    super.connectedCallback()
    this.gesuchId = getNavigationId()
    this.prioUpListener = event => this._updatePrioOfPerson(event.detail.value.rowId, +1);
    this.prioDownListener = event => this._updatePrioOfPerson(event.detail.value.rowId, -1);
    //Event Listener when prio gets changed in table
    document.addEventListener(VerantwortlichePersonPrioCell.EVENT_KEY_MOVE_PRIO_UP, this.prioUpListener)
    document.addEventListener(VerantwortlichePersonPrioCell.EVENT_KEY_MOVE_PRIO_DOWN, this.prioDownListener)
    this.verantwortlichePersonListService = await this.verantwortlichePersonService.getListService(this.gesuchId)
    this._reloadList()
    this.render()
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener(VerantwortlichePersonPrioCell.EVENT_KEY_MOVE_PRIO_UP, this.prioUpListener);
    document.removeEventListener(VerantwortlichePersonPrioCell.EVENT_KEY_MOVE_PRIO_DOWN, this.prioDownListener);

  }

  getTemplate() {
    return html`
      <vzavg-title text="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.header')}">
        <div slot="center" class="title-content">
          <isceco-button
            id="buttonDokumenteAnzeigen"
            icon="file pdf outline"
            variant="secondary"
            @click="${_ => this._openDokumenteViewer()}"
          ></isceco-button>
        </div>
        <div slot="right" class="title-content">
          <isceco-button
            id="verantw-person-add-btn"
            title="${this.i18n.translate('geschaeftsvorfall.verantwortliche.person.button.create')}"
            icon="plus"
            variant="primary"
            @click="${_ => navigate({
              to: geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash,
              id: this.gesuchId
            })}"
            ?disabled="${AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco)}"
          ></isceco-button>
          <isceco-button
            id="buttonWeiter"
            variant="primary"
            icon="chevron right"
            @click="${_ => this._handleClickNext()}"
          ></isceco-button>
        </div>
      </vzavg-title>

      ${this._getTemplateList()}
    `
  }

  _openDokumenteViewer() {
    const to = `#${geschaeftsVorfallNavigationLinks.documentViewer.hash}`
    const id = this.gesuchId
    window.open(constructURL({to, id}), "_blank");
  }

  async updateTaetigBisDate(row, taetigBis) {
    const person = await this.verantwortlichePersonService.getVerantwortlichePerson(this.gesuchId, row.id);
    person.updateFromPersonPage = true
    person.taetigBis = taetigBis
    this.verantwortlichePersonService.updateVerantwortlichePerson(person, this.gesuchId).then(() => {
      this._reloadList()
      showAlert(
        this.i18n.translate('geschaeftsvorfall.verantwortliche.person.update.success.title'),
        this.i18n.translate('geschaeftsvorfall.verantwortliche.person.update.success.message'),
        'success'
      )
      send(GeschaeftsvorfallNavigation.EVENT_KEYS.RELOAD_STATUS_TRIGGERED)
    })
  }


  _getTemplateList() {
    const rowVariant = {
      get: row => {
        if (!row.prio) {
          return 'summary'
        }
        return ''
      }
    }
    return this.columns && this.data ? html`
      <isceco-static-list
        id="verantw-person-list"
        .data="${this.data}"
        .columns="${this.columns}"
        .i18n="${this.i18n}"
        .rowVariant="${rowVariant}"
        .mobileView="${DefaultMobileItem.getDefaultRenderer(this.i18n)}"
      ></isceco-static-list>
    ` : html``
  }

  _reloadList() {
    const queryParam = {sort: 'prio', order: 'asc'}
    this.verantwortlichePersonListService.list(queryParam).then(res => {
      this.data = res.json.sort(this._sortListByPrio).map(item => ({dto: item}));
      const maxPrio = this.data.filter(i => !!i.dto.prio).length;
      this.columns = [
        VerantwortlichePersonColumns.PRIO_COLUMN(maxPrio),
        VerantwortlichePersonColumns.STATUS_COLUMN(),
        VerantwortlichePersonColumns.NAME_COLUMN(),
        VerantwortlichePersonColumns.VORNAME_COLUMN(),
        VerantwortlichePersonColumns.GEB_DATUM_COLUMN(),
        VerantwortlichePersonColumns.TAETIG_VON_COLUMN(),
        VerantwortlichePersonColumns.TAETIG_BIS_COLUMN({
          hasWriteAccess: !AuthorizationFilter.notHasWriteAccessOrIsClosed(this.isClosedForKanton, this.isClosedForSeco),
          personObject: this
        }),
        VerantwortlichePersonColumns.PERSON_STATUS_COLUMN(),
        VerantwortlichePersonColumns.AKTIONEN_COLUMN({callback: this._rowActionMenu})
      ]
      this.render();
    });
  }

  _sortListByPrio(a, b) {
    const statusOrder = {
      'inaktiv': 1,
      'gesperrt': 2
    };

    const statusA = a.personStatus;
    const statusB = b.personStatus;

    // Compare status values using the custom order
    const statusComparison = (statusOrder[statusA] || 0) - (statusOrder[statusB] || 0);

    // If statuses are equal or not in the custom order, sort by prio
    if (statusComparison === 0) {
      return a.prio - b.prio;
    }

    return statusComparison;
  }

  _rowActionMenu = (row, action) => {
    if (action === 'edit') {
      navigate({to: geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash, id: `${this.gesuchId}/${row.id}`})
    }
  }

  _handleClickNext() {
    const link = geschaeftsVorfallNavigationLinksHelper.next()
    navigate({to: link.hash, id: this.gesuchId})
  }

  /**
   * Updates the prio of a person
   * @param {number} id
   * @param {number} amount
   */
  _updatePrioOfPerson(id, amount) {
    this.verantwortlichePersonService.updateVerantwortlichePersonPrio({id: id}, this.gesuchId, amount)
      .then(_ => this._reloadList())
  }

}
customElements.define('vzavg-frontend-geschaeftsvorfall-verantwortlicheperson', VerantwortlichePerson)

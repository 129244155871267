import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Navigation from '../Navigation/Navigation.js'
import NoContent from '../NoContent/NoContent.js'
import LogSettings from '../LogSettings/LogSettings.js'
import NotAuthorized from '../NotAuthorized/NotAuthorized.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'
import Authorization from '../Authorization/Authorization.js'
import Geschaeftsvorfall, {geschaeftsVorfallNavigationLinks} from '../Geschaeftsvorfall/Geschaeftsvorfall.js'
import News from '../News/News.js'
import Cockpit from '../Cockpit/Cockpit.js'
import Suchen, {suchenNavigationLinkMap, suchenScopes} from '../Suchen/Suchen.js'
import Administration from '../Administration/Administration.js'
import Auswertung from '../Auswertung/Auswertung.js'
import Statistikdaten, {statistikdatenNavigationLinks} from '../Statistikdaten/Statistikdaten.js'
import DokumenteViewer from '../Geschaeftsvorfall/Dokumente/DokumenteViewer.js'

export default class MainView extends WebComponent {

  get css() {
    return css`
      :host {
        overflow: hidden;
      }

      div {
        text-align: left;
      }

    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.oldChild = null

    this.navigationListener = e => {
      this.loadView(e.detail)
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)

    this.loadView(getNavigationHash())
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  loadView(linkName) {
    const newChild = this.returnNewChild(linkName)

    if (this.oldChild) {
      this.root.replaceChild(newChild, this.oldChild)
    } else {
      this.root.appendChild(newChild)
    }

    this.oldChild = newChild
  }

  returnNewChild(linkName) { //NOSONAR
    if (!AuthorizationFilter.viewAllowed(linkName)) {
      return new NotAuthorized()
    }

    if (isEmpty(linkName)) {
      if (hasRole(Authorization.ROLES().betrieb)) {
        return new LogSettings()
      } else {
        return new Cockpit()
      }
    }

    switch (linkName) {
      case 'News':
        return new News()
      case 'Cockpit':
        return new Cockpit()
      case suchenNavigationLinkMap.get(suchenScopes.global):
      case suchenNavigationLinkMap.get(suchenScopes.betrieb):
      case suchenNavigationLinkMap.get(suchenScopes.pendenz):
      case suchenNavigationLinkMap.get(suchenScopes.notiz):
      case suchenNavigationLinkMap.get(suchenScopes.verantwortlichep):
      case suchenNavigationLinkMap.get(suchenScopes.kaution):
      case suchenNavigationLinkMap.get(suchenScopes.gesuch):
      case suchenNavigationLinkMap.get(suchenScopes.bewilligung):
        return new Suchen()
      case 'Administration':
      case 'Administration_Hilfe':
      case 'Administration_Branchen':
      case 'Administration_Stammdatenpflege':
      case 'Administration_Stammdatenpflege_Kast':
      case 'Administration_Stammdatenpflege_KastEdit':
      case 'Administration_Stammdatenpflege_Benutzer':
      case 'Administration_Stammdatenpflege_BenutzerEdit':
      case 'Administration_Checklisten':
      case 'Administration_ChecklistenEdit':
      case 'Administration_Dokumentation':
      case 'Administration_Rechte':
        return new Administration()
      case 'Auswertung':
      case 'Auswertung_1':
      case 'Auswertung_2':
      case 'Auswertung_3':
      case 'Auswertung_4':
        return new Auswertung()
      case statistikdatenNavigationLinks.freigeben.hash:
      case statistikdatenNavigationLinks.konsolidieren.hash:
      case statistikdatenNavigationLinks.erhebung_abschliessen.hash:
      case statistikdatenNavigationLinks.erhebung_freigeben.hash:
      case 'Statistikdaten':
        return new Statistikdaten()
      case 'Geschaeftsvorfall':
      case geschaeftsVorfallNavigationLinks.uebersicht.hash:
      case geschaeftsVorfallNavigationLinks.betrieb.hash:
      case geschaeftsVorfallNavigationLinks.gesuch.hash:
      case geschaeftsVorfallNavigationLinks.adresse.hash:
      case geschaeftsVorfallNavigationLinks.adresseEdit.hash:
      case geschaeftsVorfallNavigationLinks.verantwortlichePerson.hash:
      case geschaeftsVorfallNavigationLinks.verantwortlichePersonEdit.hash:
      case geschaeftsVorfallNavigationLinks.kaution.hash:
      case geschaeftsVorfallNavigationLinks.kautionEdit.hash:
      case geschaeftsVorfallNavigationLinks.dokumente.hash:
      case geschaeftsVorfallNavigationLinks.dokumenteEdit.hash:
      case geschaeftsVorfallNavigationLinks.entscheid.hash:
      case geschaeftsVorfallNavigationLinks.bewilligung.hash:
      case geschaeftsVorfallNavigationLinks.bewilligungEdit.hash:
      case geschaeftsVorfallNavigationLinks.statistikDaten.hash:
      case geschaeftsVorfallNavigationLinks.notiz.hash:
      case geschaeftsVorfallNavigationLinks.notizEdit.hash:
      case geschaeftsVorfallNavigationLinks.pendenz.hash:
      case geschaeftsVorfallNavigationLinks.pendenzEdit.hash:
      case geschaeftsVorfallNavigationLinks.history.hash:
      case geschaeftsVorfallNavigationLinks.techInfos.hash:
        return new Geschaeftsvorfall()
      case geschaeftsVorfallNavigationLinks.documentViewer.hash:
        return new DokumenteViewer()
      case 'LogSettings':
        return new LogSettings()
      default:
        return new NoContent()
    }
  }

  getTemplate() {
    return html`vzavg-frontend-main-view`
  }

}

customElements.define('vzavg-frontend-main-view', MainView)

import ExceptionService from './ExceptionService.js'
import ApplicationException from './ApplicationException.js'
import ConfigUtil from './services/ConfigUtil.js'

export default class BaseService {

  constructor(relativeBase) {
    this.backendUrl = ''
    this.relativeBase = relativeBase
    this.logging = new ExceptionService()
    this.configUtil = new ConfigUtil()
  }

  retrieveBackendUrl() {
    return this.configUtil.retrieveConfiguration()
      .then(config => {
        this.backendUrl = `${config.backendUrl}/${this.relativeBase}`
      })
      .catch(error => {
        this.logging.log(error)
      })
  }

  async getBackendListUrl(serviceName) {
    await this.retrieveBackendUrl()
    return serviceName ? `${this.backendUrl}/${serviceName}` : this.backendUrl
  }

  async list(serviceName) {
    await this.getBackendListUrl(serviceName).then(url => this.serviceurl = url)
    return this.checkError(
      fetch(this.serviceurl, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async create(object, commandSuffix = '') {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${commandSuffix}`, {
        method: 'POST',
        cache: 'reload',
        headers: this.getJsonHeader(),
        body: JSON.stringify(object)
      }).then(this._resetChangesAndReturn)
    )
  }

  async read(id, subResource = '') {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${id}/${subResource}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async update(object, serviceName) {
    await this.retrieveBackendUrl();
    const url = (serviceName) ? `${this.backendUrl}/${serviceName}` : this.backendUrl
    return this.checkError(
      fetch(`${url}/${object.id}`, {
        method: 'PUT',
        cache: 'reload',
        headers: this.getJsonHeader(),
        body: JSON.stringify(object)
      }).then(this._resetChangesAndReturn)
    )
  }

  async delete(id, subResource = '') {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${id}/${subResource}`, {
        method: 'DELETE',
        cache: 'reload',
        headers: {
          'Authorization': 'Bearer ' + window.keycloak.token,
          'Accept-Language': getLanguage()
        }
      })
    )
  }

  async checkError(promise) {
    this.configUtil.updateToken()
    return promise
      .then(result => {
        if (result.ok) {
          const contentType = result.headers.get('content-type')
          if (contentType && contentType.indexOf('application/json') !== -1) {
            return result.json()
          } else {
            return result
          }
        } else {
          result.json().then(err => {
          if (err.reasons.length > 0) {
            showAlert(err.title, err.detail, 'error',  err.reasons.map(reason => `${reason.field}: ${reason.text}`))
          } else {
            showAlert(err.title, err.detail, 'error');
          }
        })

          throw new ApplicationException(result.status)
        }
      })
      .catch(error => {
        if (error instanceof ApplicationException) {
          throw error
        } else {
          showAlert('Service unavailable', 'Request to backend could not be sent', 'error')
          this.logging.log(error)
          throw error
        }
      })
  }

  getJsonHeader() {
    return {
      'Authorization': 'Bearer ' + window.keycloak.token,
      'Content-Type': 'application/json',
      'Accept-Language': getLanguage()
    }
  }

  _resetChangesAndReturn = res => {
    window.hasChanges = false;
    return res
  }

}

import BaseService from '../BaseService.js'

export default class StammdatenService extends BaseService {

  constructor() {
    super('stammdaten')
  }

  async getGeschaeftsvorfallkategorien() {
    return this.list('geschaeftsvorfalllkategorie')
  }

  async getKantone() {
    return this.list('kanton')
  }

  async getQualifikationen() {
    return this.list('qualifikation')
  }

  async getBranchen() {
    return this.list('branche')
  }

  async getBetriebsarten() {
    return this.list('betriebsart')
  }

  async getAenderungsgruende() {
    return this.list('aenderungsgrund')
  }

  async getAdresseTypen() {
    return this.list('adressetyp')
  }

  async getAufenthaltsbewilligung() {
    return this.list('aufenthaltsbewilligung')
  }

  async getTeilzeitGrund() {
    return this.list('teilzeitgrund')
  }

  async getOrganisation() {
    return this.list('organisation')
  }

  async getNotizKategorien() {
    return this.list('notizkategorie')
  }

  async getKautionsart() {
    return this.list('kautionsart')
  }

  async getDokumentId() {
    return this.list('dokumentId')
  }

  async getEntscheidGesuch() {
    return this.list('entscheidGesuch')
  }

  async getBewilligunsgrund() {
    return this.list('bewilligungsgrund')
  }

  async getBewilligungsaktion() {
    return this.list('bewilligungsaktion')
  }

}

import {css, html, LitElement} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'

export default class ListCreator extends LitElement {
  static get properties() {
    return {
      title: {type: String},
      items: {type: Array},
      newItem: {type: String},
      required: {type: Boolean}
    }
  }

  static get styles() {
    return css`
      :host(:not(.error)) #message {
        display: none;
      }

      #message {
        margin: 8px 0 0;
        font-weight: normal;
        color: var(--isceco-color-red-500);
        font-size: 0.875rem;
      }

      .input-section {
        display: flex;
        flex-wrap: wrap
      }

      isceco-text-input {
        min-width: 10em;
        margin-top: -4px;
        flex-grow: 1;
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 0.2em;
        margin-bottom: 1em;
      }

      .item {
        display: flex;
        flex-wrap: wrap;
      }

      .value {
        display: flex;
        align-items: center;
        flex: 1;
      }
    `;
  }

  constructor() {
    super()
    this.items = []
  }

  connectedCallback() {
    super.connectedCallback()
    this.newItem = ''
    if (!this.items){
      this.items = []
    }
    this.editItemIndex = -1
  }

  render() {
    return html`
      <style>@import '${iscecoWidgetLibrary.iconCss}'</style>
      <isceco-title
        text="${this.title}"
      ></isceco-title>
      ${this._renderList()}
      <div class="input-section">
        <isceco-text-input
          id="new-list-item-value"
          value="${this.newItem}"
          @change="${e => this.newItem = e.detail.value}"
        ></isceco-text-input>
        <isceco-button
          id="new-list-item"
          variant="primary"
          icon="plus"
          @click="${_ => this._addListItem()}"
        ></isceco-button>
      </div>
      <p id="message">
        <i class="exclamation triangle icon"></i>
        ${this.message}
      </p>
    `
  }

  _renderList() {
    return html`
      <div class="list">
        ${this.items.map((item, index) => html`
          <div class="item">
            ${this.editItemIndex === index ?
            html`
              <isceco-text-input
                id="edit-item-content-${index}"
                value="${item}"
              ></isceco-text-input>
              <div style="display: flex; gap: 0.5em">
                <isceco-button
                  id="btn-items-delete-${index}"
                  variant="secondary"
                  icon="save"
                  @click="${e => this._saveItem(index)}"
                ></isceco-button>
                <isceco-button
                  id="btn-items-delete-${index}"
                  variant="secondary"
                  icon="trash"
                  @click="${e => this._removeListItem(index)}"
                ></isceco-button>
              </div>
            ` : html`
                <div class="value">
                  <p style="margin-left:0.8em">${item}</p>
                </div>
                <div style="display: flex; gap: 0.5em">
                  <isceco-button
                    id="btn-items-delete-${index}"
                    variant="secondary"
                    icon="edit"
                    @click="${e => this._editItem(index)}"
                  ></isceco-button>
                  <isceco-button
                    id="btn-items-delete-${index}"
                    variant="secondary"
                    icon="trash"
                    @click="${e => this._removeListItem(index)}"
                  ></isceco-button>
                </div>
              `}
          </div>`)}
      </div>
    `
  }

  validate = () => {
    if (!this.required) {
      return true
    }

    let isValid = true
    if (this.items.length === 0) {
      isValid = false
    }
    if (isValid) {
      this.classList.remove('error')
      this.message = ''
    } else {
      this.classList.add('error')
      this.message = translateText(this._translations(), 'empty')
    }

    this.requestUpdate()
    return isValid
  }

  getValue = () => this.items

  _addListItem() {
    if (isEmpty(this.newItem)) {
      return
    }
    this.newItem = this.newItem.trim()
    if (isEmpty(this.newItem)) {
      return
    }

    this.items.push(this.newItem)
    this.validate()

    this.newItem = ''
    this.classList.remove('error')
    this.message = ''
  }

  _removeListItem(index) {
    this.items.splice(index, 1)
    this.requestUpdate()
  }

  _editItem(index) {
    this.editItemIndex = index
    this.requestUpdate()
  }

  _saveItem(index) {
    this.editItemIndex = -1
    this.items[index] = this.shadowRoot.getElementById(`edit-item-content-${index}`).getValue()
    this.requestUpdate()
  }

  _translations() {
    return {
      de: {
        empty: 'Die Liste darf nicht leer sein.'
      },
      fr: {
        empty: 'La liste ne doit pas être vide.'
      },
      it: {
        empty: 'La lista non può essere vuota.'
      },
      en: {
        empty: 'The list cannot be empty.'
      }
    }
  }
}

customElements.define('list-creator', ListCreator)

import WebComponent from '../../../WebComponent.js'
import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Form/Form.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/TextInput/TextInput.js'
import '../../../components/ListCreator/ListCreator.js'
import ChecklistenService from './ChecklistenService.js'

export default class ChecklistenEdit extends WebComponent {
  constructor() {
    super();
    this.checklistenService = new ChecklistenService()
  }

  connectedCallback() {
    super.connectedCallback()

    this.checklist = {}
    this.errorMsg = ''

    this._refresh = () => {
      this._getChecklistAndRender()
    }

    this._refresh()
  }

  getTemplate() {
    return html`
      <div>
        <isceco-button
          id="checklisten-back"
          variant="secondary"
          icon="arrow left"
          text="${this.i18n.translate('checklisten.back.to.list')}"
          @click="${_ => this._backToChecklistenList()}"
        ></isceco-button>
        <isceco-dialog
          hidden
          id="checklisten-confirm-change-dialog"
          description="${this.i18n.translate('checklisten.confirm.changes.dialog.description')}"
          confirm-button="${this.i18n.translate('checklisten.confirm.changes.button.confirm')}"
          cancel-button="${this.i18n.translate('checklisten.confirm.changes.button.cancel')}"
          @submit="${e => this._closeConfirmChangeDialog(e)}"
        ></isceco-dialog>
      </div>
      <isceco-title
        id="page-title"
        size="large"
        text="${this.i18n.translate('checklisten.create.title')}">
        <nav class="title-navigation">
          <isceco-button
            id="checklisten-speichern"
            variant="primary"
            icon="save"
            text="${this.i18n.translate('checklisten.create.button.send')}"
            @click="${_ => this._save()}"
          ></isceco-button>
        </nav>
      </isceco-title>
      <isceco-form id="checklisten-create-form">
        <div slot="form-elements">
          <section>
            <isceco-dropdown
              id="checklisten-edit-typ"
              name="kategorie"
              label="${this.i18n.translate('checklisten.kategorie')}"
              .items="${[
                {value: 'Betrieb', name: this.i18n.translate('checklisten.create.typ.betrieb')},
                {value: 'Adresse', name: this.i18n.translate('checklisten.create.typ.adresse')},
                {value: 'VerantwortlichePerson', name: this.i18n.translate('checklisten.create.typ.verantwortliche.person')},
                {value: 'Gesuch', name: this.i18n.translate('checklisten.create.typ.gesuch')},
                {value: 'Kaution', name: this.i18n.translate('checklisten.create.typ.kaution')}
              ]}"
              value="${this.checklist.kategorie}"
              required
            ></isceco-dropdown>
            <isceco-text-input
              id="checklisten-edit-gueltigVon"
              name="gueltigVon"
              label="${this.i18n.translate('checklisten.gueltig.von')}"
              type="date"
              value="${this.checklist.gueltigVon}"
              required
            ></isceco-text-input>
            <isceco-dropdown
              id="checklisten-edit-bereich"
              name="bereich"
              label="${this.i18n.translate('checklisten.bereich')}"
              .items="${[
                {value: 'kanton', name: this.i18n.translate('checklisten.create.bereich.kanton')},
                {value: 'seco', name: this.i18n.translate('checklisten.create.bereich.seco')}
              ]}"
              value="${this.checklist.bereich}"
              required
            ></isceco-dropdown>
          </section>
          <section class="columns-3">
            <div class="section-max">
              <list-creator
                id="germanList"
                name="germanList"
                title="${this.i18n.translate('checklisten.create.list.title.de')}"
                .items="${this.checklist.itemsDe}"
                required
              ></list-creator>
            </div>
            <div class="section-max">
              <list-creator
                id="frenchList"
                name="frenchList"
                title="${this.i18n.translate('checklisten.create.list.title.fr')}"
                .items="${this.checklist.itemsFr}"
                required
              ></list-creator>
            </div>
            <div class="section-max">
              <list-creator
                id="italianList"
                name="italianList"
                title="${this.i18n.translate('checklisten.create.list.title.it')}"
                .items="${this.checklist.itemsIt}"
                required
              ></list-creator>
            </div>
          </section>
          ${this.errorMsg !== '' ?
            html`
            <p style="color: var(--isceco-color-red-500); font-size: 0.875rem;">
              <i class="exclamation triangle icon" style="color: var(--isceco-color-red-500);"></i>
              ${this.errorMsg}
            </p>
          ` : ''
          }

        </div>
      </isceco-form>
    `
  }

  get css() {
    return css`
      div[slot='form-elements'] > section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        gap: 2em;
      }

      div[slot='form-elements'] > section > * {
        min-width: 10em;
        flex-grow: 1;
      }

      .section-max {
        max-width: calc(100vw - 25em);
        overflow: scroll;
      }

      @media (max-width: 991.98px) {
        .section-max {
          max-width: calc(100vw - 7em);
        }

        .columns-3 {
          display: flex;
          flex-direction: column;
        }
      }

      .columns-3 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    `
  }

  _save() {
    const form = document.getElementById('checklisten-create-form');
    if (!form.validate()) {
      return;
    }

    const germanCount = this._getNbSteps('germanList')
    const frenchCount = this._getNbSteps('frenchList')
    const italianCount = this._getNbSteps('italianList')

    if (germanCount !== frenchCount || germanCount !== italianCount) {
      this.errorMsg = this.i18n.translate('checklisten.error.count.different')
      this.reload()
    } else {
      this.errorMsg = ''
      this._saveNewChecklist(form);
    }
  }

  _getNbSteps(id){
    return document.querySelector(`#${id}`).getValue().length
  }

  _saveNewChecklist(form) {
    this.checklistenService.create(form.getValues())
      .then(_ => {
        showAlert(
          this.i18n.translate('checklisten.create.success.title'),
          this.i18n.translate('checklisten.create.success.message'),
          'success'
        );
        navigate({to: 'Administration_Checklisten'})
      })
  }
  _backToChecklistenList() {
    if (this.hasChanges) {
      const dialog = document.getElementById('checklisten-confirm-change-dialog')
      dialog.hidden = false
    } else {
      navigate({to: 'Administration_Checklisten'})
    }
  }

  _closeConfirmChangeDialog(e) {
    e.target.hidden = true;
    if (e.detail.confirmed) {
      navigate({to: 'Administration_Checklisten'})
    }
  }

  get translationFile() {
    return './views/Administration/Checklisten/i18n.json'
  }

  _getChecklistAndRender() {
    const id = getNavigationId();
    if (id == null) {
      this.checklist.gueltigVon = new Date().toISOString().split('T')[0]
      this.render()
      return
    }

    this.checklistenService.read(id)
      .then(json => {
        this.checklist = json
        this.checklist.items.sort((a, b) => a.priority - b.priority)

        this.checklist.itemsDe = []
        this.checklist.itemsFr = []
        this.checklist.itemsIt = []

        for (const item of this.checklist.items) {
          switch (item.language) {
            case 'de':
              this.checklist.itemsDe.push(item.item)
              break
            case 'fr':
              this.checklist.itemsFr.push(item.item)
              break
            case 'it':
              this.checklist.itemsIt.push(item.item)
              break
            default:
              break
          }
        }

        this.checklist.itemsDe.sort((a, b) => a.priority - b.priority)
        this.checklist.itemsFr.sort((a, b) => a.priority - b.priority)
        this.checklist.itemsIt.sort((a, b) => a.priority - b.priority)
        this.render()

        if (containsAlert()) {
          send('isceco-alert-happend')
        }
      })
      .catch(_ => {
        showAlert('', this.i18n.translate('checklisten.edit.error'))
        this.render()
      })
  }
}
customElements.define('vzavg-frontend-checklisten-edit', ChecklistenEdit)

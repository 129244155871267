import BaseService from '../BaseService.js'
import VzavgListService from './VzavgListService.js'

export default class VerantwortlichePersonService extends BaseService {
  constructor() {
    super('gesuch')
  }

  async getListService(gesuchId) {
    return this.getBackendListUrl(`${gesuchId}/verantwortlichep`)
      .then(url => new VzavgListService(url))
  }

  async getListServiceForDocumentUpload(gesuchId) {
    return this.getBackendListUrl(`${gesuchId}/verantwortlichep/forDocumentUpload`)
      .then(url => new VzavgListService(url))
  }

  async readVerantwortlichePersonChecklist(gesuchId, personId) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/verantwortlichep/${personId}/checklists`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  async getVerantwortlichePerson(gesuchId, verantwPersonId) {
    return super.read(gesuchId, `verantwortlichep/${verantwPersonId}`)
  }

  async updateVerantwortlichePerson(formDatas, gesuchId) {
    return super.update(formDatas, `${gesuchId}/verantwortlichep`)
  }

  /**
   * Gets other Betrieb when Person works in other Betrieb
   * @param {String} sozVerNumber
   * @returns {Promise<void>}
   */
  async getVerantwortlichePersonBetrieb(gesuchId, sozVerNumber) {
    await this.retrieveBackendUrl()
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/verantwortlichep/otherBetriebe/${sozVerNumber}`, {
        method: 'GET',
        cache: 'reload',
        headers: this.getJsonHeader()
      })
    )
  }

  /**
   * @typedef {object} PrioModel
   * @property {number} id
   * @property {number} prio
   *
   * @param {PrioModel[]} data
   * @param {number} gesuchId
   * @param {number} amount
   * @returns {Promise<*>}
   */
  async updateVerantwortlichePersonPrio(data, gesuchId, amount) {
    await this.retrieveBackendUrl()
    const url = amount === 1 ? 'Up' : 'Down'
    return this.checkError(
      fetch(`${this.backendUrl}/${gesuchId}/verantwortlichep/prio${url}`, {
        method: 'PUT',
        cache: 'reload',
        headers: this.getJsonHeader(),
        body: JSON.stringify(data)
      })
    )
  }

  async createVerantwortlichePerson(formDatas, gesuchId) {
    return super.create(formDatas, `${gesuchId}/verantwortlichep`)
  }
}
